import PageWrapper from "./PageWrapper";

const Login = () => {
	return (
		<PageWrapper forceReadableWidth>
			<h1>Whoops!</h1>
			<p>While we rework the dashboard, a link to your app portal has been sent to your email.</p>
		</PageWrapper>
	);
};

export default Login;
