import { Helmet } from "react-helmet";
import PageWrapper from "./PageWrapper";

const Etc = () => (
	<PageWrapper forceReadableWidth>
		<Helmet>
			<title>/etc</title>
		</Helmet>
		<h1>/etc</h1>
		<h2>Thanks</h2>
		<p>Thanks for visiting. We hope you enjoyed your stay.</p>
		<h2>Contact</h2>
		<p>
			We are most responsive through regular email, you can <a href={"mailto:ops@cyn.gg"}>click here</a>. We reply ASAP.
		</p>
		<p>
			If your inquiry is related to a network I manage or control (e.g. AS399371), please send an email to my email
			listed on the respective WHOIS contact. If it's an emergency, you can email{" "}
			<a href="mailto:ops@cyn.gg">ops@cyn.gg</a>.
		</p>
		<h2>Other</h2>
		<ul>
			<li>
				PGP Fingerprint: <code>D740 E413 3A74 815E F5B6 F11B AF8B EE0C CF1D 0E46</code>
			</li>
			<li>
				Primary ETH Address: <code>cyn.eth (0xd3Ab46F05458f1AB26e)</code>
			</li>
		</ul>
	</PageWrapper>
);

export default Etc;
