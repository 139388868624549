import PageWrapper from "./PageWrapper";
import Repo from "../components/Repo";
import { Helmet } from "react-helmet";

const Where = () => {
	return (
		<PageWrapper>
			<Helmet>
				<title>What</title>
			</Helmet>

			<h3>Here is what we do best:</h3>
			<Repo
				name={"Applications"}
				description="Build & deploy Static Sites, any Node.js based applications, PHP, Rust applications hassle-free with automatic reverse proxy and SSL certs!"
			/>
			<Repo
				name={"Databases"}
				description="One-click MongoDB, MySQL, PostgreSQL, CouchDB, RedisDB instances ready to use - with one-click backups!"
			/>
			<Repo
				name={"Services"}
				description="Need your own instance of WordPress, Plausible Analytics, NocoDB, MinIO or VSCode Server ? You covered here!"
			/>
		</PageWrapper>
	);
};

export default Where;
