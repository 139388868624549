import styled from "styled-components";

const Repo = ({ name, description }: { name: string; description: string }) => {
	return (
		<Container>
			<Column forceWidth={150}>
				<h5>name</h5>
				<p>{name}</p>
			</Column>
			{/* <Column forceWidth={150}>
				<h5>language</h5>
				<p>
					{primaryLanguage}{" "}
					<LanguageColorBlip
						color={
							primaryLanguage === "Elixir"
								? "#9c1fa5"
								: primaryLanguage === "JavaScript"
								? "#007acc"
								: primaryLanguage === "TypeScript"
								? "#007acc"
								: primaryLanguage === "Rust"
								? "#dea584"
								: "#007acc"
						}
					/>
				</p>
			</Column> */}
			<Column noBorder>
				<h5>description</h5>
				<p>{description}</p>
			</Column>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100px;
	border: 1px solid #101010;
	border-radius: 10px;
	overflow: hidden;
	margin-bottom: 1rem;

	@media (max-width: 850px) {
		flex-direction: column;
		height: auto;
	}

	&:hover {
		background-color: #101010;
	}
`;

const Column = styled.div<{ forceWidth?: number; noBorder?: boolean }>`
	display: block;
	height: 100%;
	width: ${({ forceWidth }) => (forceWidth ? forceWidth + "px" : "fit-content")};
	min-width: ${({ forceWidth }) => (forceWidth ? forceWidth + "px" : undefined)};
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-right: 1px solid ${({ noBorder }) => (noBorder ? "transparent" : "#101010")};
	padding: 1rem;
	box-sizing: border-box;
	flex-shrink: ${({ noBorder }) => (noBorder ? undefined : 0)};

	@media (max-width: 850px) {
		height: 50px;
		width: 100%;
		border-bottom: 1px solid ${({ noBorder }) => (noBorder ? "transparent" : "#101010")};
		padding: 1rem;
		flex-shrink: 0;
		box-sizing: content-box;
	}

	h5 {
		font-family: "Courier New";
		margin: 0;
		color: #fff;
	}

	p {
		margin: 0;
		white-space: normal;
	}
`;

export default Repo;
