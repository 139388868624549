import PageWrapper from "./PageWrapper";

const Home = () => {
	return (
		<PageWrapper forceReadableWidth>
			<h1>What We Do</h1>
			<p>The simple cloud PaaS to develop websites and web apps.</p>
			<p>
				We eliminate the need to build, manage, and maintain core infrastructure. We do it and we keep it carbon
				negative.
			</p>

			<p>
				You can build amazing websites and web apps in the languages and frameworks of your choice. While developing on
				a single, global, secured cloud infrastructure, and solve multiple customer needs across different industries
				and geographies. And still, benefit from automated deployments, stable environments, and a consistent
				development workflow—without managing infrastructure.
			</p>

			<h3>Vouches</h3>
			<p>
				The deployment model, the ability to have any type of project container that we want (not limited to just
				WordPress, etc.), and the support from the Cyn.gg team.
				<br />- Felix B.
			</p>
			<p>
				Removes the need for devops skills while still having access to a broad range of services, highly reliable and
				great support and pro-active security.
				<br />- Tylerr L.
			</p>
		</PageWrapper>
	);
};

export default Home;
